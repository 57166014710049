import { defineStore } from 'pinia';
import { User } from '@js/api';

export default defineStore({
  id: 'user',
  state: () => ({
    id: null,
    name: '',
    avatarUrl: null,
    avatar: null,
    avatarFile: null,
    email: '',
    phone: '',
    gdprAccepted: false,
    isExternalSSO: false,
    jobTitle: '',
    twoFactor: '',
    isLoading: true,
    isLoaded: false,
  }),
  getters: {

  },
  actions: {
    async getUserData() {
      this.isLoading = true;
      await User.getCurrentUser().fetch().then(({ data }) => {
        this.$state = data.data;
        if (data.data.avatarFile !== null) {
          this.avatar = data.data.avatarFile.thumbnail;
        }
      }).catch((error) => {
        console.error('Error retrieving current user profile data:', error);
      })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
});
