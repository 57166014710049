export default {

  INJECT: Object.freeze({
    CRM_FIELDS: 'crmFields',
  }),

  METHOD: Object.freeze({
    UPDATE: 'update',
    CREATE: 'create',
  }),

  FORMS: Object.freeze({
    BRANDING: 'branding',
    TERMINOLOGY: 'terminology',
    REGIONAL_OFFICE: 'regionalOffice',
    SECTOR: 'sector',
    REGION: 'region',
    FRAMEWORK: 'framework',
    PERSON: 'person',
    ORGANISATION: 'organisation',
    CONTRACTOR: 'contractor',
    PROJECT: 'project',
    USER_DETAILS: 'userDetails',
    USER_PERMISSIONS: 'userPermissions',
    USER_PROFILE: 'userProfile',
  }),

  FIELDS: Object.freeze({
    BRANDING: Object.freeze({
      NAME: 'name',
      LOGO_UPLOADED: 'logoUploaded',
      LOGO_DELETED: 'logoDeleted',
      SPLASH_UPLOADED: 'splashUploaded',
      SPLASH_DELETED: 'splashDeleted',
      ADDRESS_LINE_ONE: 'addressLineOne',
      ADDRESS_LINE_TWO: 'addressLineTwo',
      CITY: 'city',
      POSTCODE: 'postcode',
    }),
    REGIONAL_OFFICE: Object.freeze({
      NAME: 'name',
      ADDRESS_LINE_ONE: 'addressLineOne',
      ADDRESS_LINE_TWO: 'addressLineTwo',
      ADDRESS_LINE_THREE: 'addressLineThree',
      TOWN_CITY: 'townCity',
      COUNTY: 'county',
      POSTCODE: 'postcode',
      PHONE_NUMBER: 'phoneNumber',
    }),
    SECTOR: Object.freeze({
      NAME: 'name',
    }),
    REGION: Object.freeze({
      NAME: 'name',
    }),
    ORGANISATION: Object.freeze({
      NAME: 'name',
      ADDRESS_LINE_ONE: 'addressLineOne',
      ADDRESS_LINE_TWO: 'addressLineTwo',
      ADDRESS_LINE_THREE: 'addressLineThree',
      TOWN_CITY: 'townCity',
      COUNTY: 'county',
      POSTCODE: 'postcode',
      PHONE_NUMBER: 'phoneNumber',
      ASSOCIATION: 'association',
    }),
    CONTRACTOR: Object.freeze({
      NAME: 'name',
      CONTRACTOR_NUMBER: 'contractorNumber',
      TRADES: 'trades',
      ADDRESSES: 'addresses',
      MICRO_BUSINESS: 'microBusiness',
      SME: 'sme',
      SOCIAL_ENTERPRISE: 'socialEnterprise',
      ASSOCIATION: 'association',
      ADDRESS: Object.freeze({
        ADDRESS_LINE_ONE: 'addressLineOne',
        ADDRESS_LINE_TWO: 'addressLineTwo',
        ADDRESS_LINE_THREE: 'addressLineThree',
        TOWN_CITY: 'townCity',
        COUNTY: 'county',
        POSTCODE: 'postCode',
        PHONE_NUMBER: 'phoneNumber',
      }),
    }),
    FRAMEWORK: Object.freeze({
      NAME: 'name',
      START_DATE: 'startDate',
      END_DATE: 'endDate',
      YEARLY_TARGETS: 'yearlyTargets',
    }),
    PERSON: Object.freeze({
      NAME: 'name',
      INDIVIDUAL_TYPE: 'individualType',
      EMPLOYER_TYPE: 'employerType',
      EMPLOYER_NAME: 'employerName',
      JOB_TITLE: 'jobTitle',
      HOME_POSTCODE: 'homePostcode',
      GENDER: 'gender',
      GENDER_SELF_DESCRIPTION: 'genderSelfDescription',
      ETHNICITY: 'ethnicity',
      DISABILITY: 'disability',
      NATIONALITY: 'nationality',
      PROJECTS: 'projects',
      ACCEPT_GDPR: 'acceptGdpr',
    }),
    PROJECT: Object.freeze({
      NAME: 'name',
      CRM_ID: 'crmId',
      CRM_STATUS: 'crmStatus',
      CONTRACT_NUMBER: 'contractNumber',
      DESCRIPTION: 'description',
      VALUE: 'value',
      ADDRESS: 'address',
      ADDRESS_LINE_ONE: 'addressLineOne',
      ADDRESS_LINE_TWO: 'addressLineTwo',
      ADDRESS_LINE_THREE: 'addressLineThree',
      COUNTY: 'county',
      PHONE_NUMBER: 'phoneNumber',
      TOWN_CITY: 'townCity',
      POSTCODE: 'postCode',
      REGIONAL_OFFICE: 'regionalOffice',
      SECTOR: 'sector',
      REGION: 'region',
      FRAMEWORK: 'framework',
      ORGANISATION: 'organisation',
      CONSIDERATE_CONSTRUCTOR_SITE_ID: 'considerateConstructorSiteId',
      COST_CENTRE: 'costCentre',
      ASSOCIATION: 'association',
      META: 'meta',
    }),
    USER_DETAILS: Object.freeze({
      NAME: 'name',
      JOB_TITLE: 'jobTitle',
      AVATAR_EXISTING: 'avatarExisting',
      AVATAR_DELETED: 'avatarDeleted',
      AVATAR_UPLOADED: 'avatarUploaded',
      EMAIL: 'email',
      PHONE: 'phoneNumber',
      ROLE: 'role',
      SEND_PASSWORD_EMAIL: 'sendPasswordEmail',
      TWO_FACTOR_ENABLED: 'twoFactor',
      IS_EXTERNAL_SSO: 'isExternalSSO',
      PASSWORD: 'password',
      REPEAT_PASSWORD: 'repeatPassword',
      PRIMARY_OFFICE: 'primaryOffice',
      OFFICES: 'offices',
      CONTRACTOR: 'contractor',
      ORGANISATION: 'organisation',
      FRAMEWORK: 'framework',
    }),
    USER_PROFILE: Object.freeze({
      NAME: 'name',
      JOB_TITLE: 'jobTitle',
      AVATAR_EXISTING: 'avatarExisting',
      AVATAR_DELETED: 'avatarDeleted',
      AVATAR_UPLOADED: 'avatarUploaded',
      EMAIL: 'email',
      PHONE: 'phone',
      CURRENT_PASSWORD: 'currentPassword',
      NEW_PASSWORD: 'newPassword',
      REPEAT_NEW_PASSWORD: 'repeatNewPassword',
      GDPR_ACCEPTED: 'gdprAccepted',
    }),
  }),
};
