import { useCreateEndpoint } from '@js/composables/useEndpoint';
import { app } from './config/api';

export default {
  /**
   * Get the current user profile data
   */
  getCurrentUser: () => useCreateEndpoint(
    (config) => () => app.get('/api/profile', config),
  ),

  /**
   * Edit current users Profile
   */
  editProfile: () => useCreateEndpoint(
    (config) => (formData) => app.post('/api/profile', formData, {
      headers: {
        'Content-Type': 'multipart/form-data; charset=utf-8;',
      },
      ...config,
    }),
  ),

  /**
   * Get the current user permissions
   */
  getUserPermissions: () => useCreateEndpoint(
    (config) => () => app.get('/api/permissions', config),
  ),

  /**
   * Get the current user notifications
   */
  getUserNotifications: () => useCreateEndpoint(
    (config) => () => app.get('/api/notifications', config),
  ),

  // /**
  //  * Generate and get the users two factor QR code
  //  *
  //  * @returns {Promise<AxiosResponse<{data:QRCodeResponse,errors:Array,metadata:Array}>>} An Axios promise with the current QR code
  //  */
  // getTwoFactorQrCode: () => api().get('/api/profile/2fa', {
  // }),
  //
  // /**
  //  * Verify two-factor code
  //  *
  //  * @param {string} code The six-digit code as a string. Required.
  //  * @returns {Promise<AxiosResponse<{data:{},errors:Array,metadata:Array}>>} An Axios promise returning either 200 for success or 400 for failure
  //  */
  // postTwoFactorCode: (code) => api().post('/api/profile/2fa-confirm', { code }),

};
