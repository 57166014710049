export default Object.freeze({
  CREDENTIALS: 'credentials',
  O_AUTH: 'oauth',
  GDPR: 'gdpr',
  FORGOT: 'forgot',
  RESET: 'reset',
  START: 'start',
  BLOCKED: 'blocked',
  ACCESS_DENIED: 'access-denied',
  MAX_ATTEMPT: 'max_attempts_reached',
  MFA: '2fa',
  INVALID_LOGIN: 'invalid_login',
});
